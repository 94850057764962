import React from "react"
import PropTypes from "prop-types"
import { Image } from "../../components/images.component"
import Link from "../navigation/link.component"
import Scroller from "../../components/scroller.component"
import styled from "styled-components"
import { colors } from "../structure/theme"
import { useTranslation } from "../translation/translate.component"
import { flexCenter } from "../structure/theme/styles.component"

const PartnerLink = styled(Link)`
  ${flexCenter};
  margin: 0 5px;
  padding: 5px;
  background: ${colors(`grey.g300`)};
  line-height: 0;
  min-width: 150px;
`

export default function WebsiteTrustedPartners ({ websiteTrustedPartners }) {
  const { t } = useTranslation()

  return (
    <Scroller>
      {websiteTrustedPartners.map(({ id, logo, url }) => (
        <PartnerLink
          key={id}
          data-testid="trusted_partner_item"
          {...(url ? { href: url, target: `_blank` } : { as: `span` })}
        >
          <Image file={logo} alt={t(`common:website.section_trusted_partner_img_alt`)} objectFit="contain" />
        </PartnerLink>
      ))}
    </Scroller>
  )
}

WebsiteTrustedPartners.propTypes = {
  websiteTrustedPartners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      logo: PropTypes.any,
      url: PropTypes.any,
    }).isRequired,
  ).isRequired,
}
